<template>
	<div class="page df fdc aic">
		<div class="step">
			<a-steps :current="current">
				<a-step v-for="item in steps" :key="item.title" :title="item.title" />
			</a-steps>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				steps: [{
						title: '确认订单',
						content: 'First-content',
					},
					{
						title: '支付订单',
						content: 'Second-content',
					},
					{
						title: '支付结果',
						content: 'Last-content',
					}
				],
			}
		},
		computed: {
			current() {
				switch (this.$route.path) {
					case '/ConfirmOrder':
						return 0;
						break;
					case '/payOrder':
						return 1;
						break;
					case '/result':
						return 2;
						break;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '@/common/scss/turn.scss';

	>>>.ant-steps-item-process {
		.ant-steps-item-icon {
			background: #000;
			border-color: #000;
		}
	}

	>>>.ant-steps-item-finish {
		.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
			background-color: #000;
		}

		.ant-steps-item-icon {
			border-color: #000;

			>.ant-steps-icon {
				color: #000;
			}
		}
	}

	.page {
		width: 100vw;
		padding-top: px2rem(182px);

		.step {
			width: 1200px;
			padding: 0 px2rem(167px);
			padding-bottom: px2rem(32);
		}
	}
</style>
